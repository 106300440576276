import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rjs-command-line-interface"
    }}>{`RJS Command Line Interface`}</h1>
    <p>{`ReworkJS comes bundled with a command line interface which handles tasks such as
launching, building or testing your app.`}</p>
    <p>{`It only comes installed locally by default. But you can install `}<a parentName="p" {...{
        "href": "https://github.com/reworkjs/cli"
      }}>{`@reworkjs/cli`}</a>{`
to gain access to it from anywhere.`}</p>
    <h2 {...{
      "id": "list-of-available-commands"
    }}>{`List of available commands`}</h2>
    <p>{`General options:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`--env <NODE_ENV>`}</inlineCode>{`: Sets the NODE_ENV environment variable for the lifetime of the process.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--verbose <verbosity>`}</inlineCode>{`: Sets the verbosity of the output. Defaults to `}<inlineCode parentName="li">{`info`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "rjs-init"
    }}><inlineCode parentName="h3">{`rjs init`}</inlineCode></h3>
    <p>{`Installs and initializes the reworkjs framework.
You can launch it again as many times as you wish to configure new elements that might have
been added after your initial installation.`}</p>
    <h3 {...{
      "id": "rjs-start"
    }}><inlineCode parentName="h3">{`rjs start`}</inlineCode></h3>
    <p>{`Builds and launches the application.`}</p>
    <p>{`The compiler will launch in watch mode if the `}<inlineCode parentName="p">{`NODE_ENV`}</inlineCode>{` variable is set to `}<inlineCode parentName="p">{`development`}</inlineCode></p>
    <p>{`Options:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`--port`}</inlineCode>{`: The port on which the static and pre-rendering server will respond to HTTP requests.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--ssr`}</inlineCode>{`: By default, `}<inlineCode parentName="li">{`rjs start`}</inlineCode>{` will launch without Server-Side Rendering to reduce compilation time. Use `}<inlineCode parentName="li">{`--ssr`}</inlineCode>{` to enable Server Side Rendering.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--no-split`}</inlineCode>{`: By default, starting the app with pre-rendering will create a split view in the terminal with the output of
the pre-rendering server builder, the client bundle builder, and the pre-rendering server itself. You can revert back
to a regular CLI display using this option.`}</li>
    </ul>
    <h3 {...{
      "id": "rjs-build"
    }}><inlineCode parentName="h3">{`rjs build`}</inlineCode></h3>
    <p>{`Builds the application.`}</p>
    <p>{`Usage: `}<inlineCode parentName="p">{`rjs build <...parts> [options]`}</inlineCode>{`  `}</p>
    <p>{`Options:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`parts`}</inlineCode>{`: Which parts of the application to build. Either "server" for the pre-rendering server, "client" for the browser bundle, or both separated by a space.`}</li>
    </ul>
    <h3 {...{
      "id": "rjs-print-config"
    }}><inlineCode parentName="h3">{`rjs print-config`}</inlineCode></h3>
    <p>{`Dumps the computed framework configuration. For debug purposes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      